<template>
  <v-container fluid v-if="!id || !value || id !== value._id">Laden...</v-container>
  <v-container fluid v-else>
    <v-row>
      <v-col cols="12">
        <h2>{{ value.person | person }} ({{ value.team.name }})</h2>
      </v-col>
      <v-col xl="6" cols="12">
        <template v-if="istSprung">
          <span class="label deduction">
            Sprung auswählen:
          </span>
          <v-row>
            <v-col
              v-for="s in favspruenge"
              cols="12"
              :key="s._id"
            >
              <v-btn
                block
                class="v-btn--text"
                @click="setSprung(s._id)"
              >
                <template v-if="s.name">
                  <b>{{ s.name }}</b>&nbsp;({{ s.beschreibung }})
                </template>
                <template v-else>
                  {{ s.beschreibung }}
                </template>
                &nbsp;<v-icon>far fa-long-arrow-alt-right</v-icon>&nbsp;<i>{{ s.punkte }}P (+{{s.bonus}} Bonus)</i>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="value._sprung"
                :items="sf"
                label="alle Sprünge"
                :item-text="(s) => `${s.nr}: ${sprungname(s)} -> ${s.punkte}P (+${s.bonus} Bonus)`"
                item-value="_id"
              >
                <template #item="{item}">
                  {{ item.nr }}:
                  <template v-if="item.name">
                    &nbsp;<b>{{ item.name }}</b>&nbsp;({{ item.beschreibung }})
                  </template>
                  <template v-else>
                    {{ item.beschreibung }}
                  </template>
                  &nbsp;<v-icon>far fa-long-arrow-alt-right</v-icon>&nbsp;<i>{{ item.punkte }}P (+{{item.bonus}} Bonus)</i>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="value.bonus"
                label="Sprungbonus"
              />
            </v-col>
            <v-col cols="12">
              <p>
                <b>Hinweis:</b> da die gesamte Sprung-Liste recht groß und unübersichtlich ist und man hier teilweise lange
                nach einem Sprung suchen musste, haben wir unten eine Filtermöglichkeit eingebaut. Wählt man dort die Optionen
                aus, so werden hier in der Auswahl lediglich die Sprünge angezeigt, auf die die Eigenschaften zutreffen.
              </p>
              <p>
                Zusätzlich lässt sich in der Auswahlliste auch nach der Sprungnummer suchen. Wichtig: auch hierbei werden
                nur Sprünge gefunden, auf die die Filterauswahl zutrifft.
              </p>
            </v-col>
            <v-col cols="6">
              <h3>Filter für Sprünge:</h3>
            </v-col>
            <v-col cols="6">
              <v-btn block class="v-btn--text" @click="setSprung(null)">Filter zurücksetzen</v-btn>
            </v-col>
            <v-col cols="12">
              <v-radio-group v-model="sprungfilter.absprung" row>
                <template #label><div><b>Absprung:</b></div></template>
                <v-radio label="vorwärts" :value="0" />
                <v-radio label="Rondat" :value="1" />
              </v-radio-group>
              <v-radio-group v-model="sprungfilter.f1" row>
                <template #label><div><b>LAD (Schrauben) 1. Flugphase:</b></div></template>
                <v-radio label="alle" :value="-1" />
                <v-radio v-for="k in sf_f1" :key="k" :label="`${k}`" :value="k" />
              </v-radio-group>
              <v-radio-group v-model="sprungfilter.lad" row>
                <template #label><div><b>LAD (Schrauben) 2. Flugphase:</b></div></template>
                <v-radio label="alle" :value="-1" />
                <v-radio v-for="k in sf_f2_lad" :key="k" :label="`${k}`" :value="k" />
              </v-radio-group>
              <v-radio-group v-model="sprungfilter.bad" row>
                <template #label><div><b>BAD (Salti) 2. Flugphase:</b></div></template>
                <v-radio label="alle" :value="-1" />
                <v-radio v-for="k in sf_f2_bad" :key="k" :label="`${k}`" :value="k" />
              </v-radio-group>
              <v-radio-group v-model="sprungfilter.ausfuehrung" row>
                <template #label><div><b>Ausführung:</b></div></template>
                <v-radio label="alle" :value="-1" />
                <v-radio v-for="k in sf_ausfuehrung" :key="k.id" :label="k.label" :value="k.id" />
              </v-radio-group>
            </v-col>          </v-row>
        </template>
        <template v-else>
          <v-col cols="12"><anzahlwaehler v-model="value.e" label="E-Teile" /></v-col>
          <v-col cols="12"><anzahlwaehler v-model="value.d" label="D-Teile" /></v-col>
          <v-col cols="12"><anzahlwaehler v-model="value.c" label="C-Teile" /></v-col>
          <v-col cols="12"><anzahlwaehler v-model="value.b" label="B-Teile" /></v-col>
          <v-col cols="12"><anzahlwaehler v-model="value.a" label="A-Teile" /></v-col>
          <v-col cols="12"><anzahlwaehler v-model="value.ne" label="NE-Teile" /></v-col>
          <v-col cols="12"><anzahlwaehler v-model="value.eg" label="Elementgruppen" :values="[0.0, 0.3, 0.5, 0.8, 1.0, 1.3, 1.5, 1.8, 2.0]" /></v-col>
        </template>
      </v-col>
      <v-col order-xl="1" xl="6" cols="12">
        <v-col v-if="kari_e1" cols="12"><punktwaehler v-model="value.e1" :label="`E1 (${kari_e1})`" /></v-col>
        <v-col v-if="kari_e2" cols="12"><punktwaehler v-model="value.e2" :label="`E2 (${kari_e2})`" /></v-col>
        <v-col v-if="kari_e3" cols="12"><punktwaehler v-model="value.e3" :label="`E3 (${kari_e3})`" /></v-col>
        <v-col v-if="kari_e4" cols="12"><punktwaehler v-model="value.e4" :label="`E4 (${kari_e4})`" /></v-col>
        <v-col cols="12"><punktwaehler v-model="value.ok" label="OK-Abzug" /></v-col>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-text-field
          :value="dscore | float2_0"
          label="D-Note"
          readonly
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          :value="escore | float2_0"
          :label="`E-Note (Abzug: ${deduction})`"
          readonly
        >
          <template #prepend>+</template>
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          :value="penalty | float2_0"
          :label="`Penalty (${parts} Teile)`"
          readonly
        >
          <template #prepend>-</template>
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          :value="final | float2_0"
          label="Endnote"
          readonly
          style="font-size: 150%;"
        >
          <template #prepend>=</template>
        </v-text-field>
      </v-col>
      <template v-if="istScore && scoregegner">
        <v-col cols="6"></v-col>
        <v-col cols="3">
          <b>Score-Gegner:</b> {{ scoregegner | person }}: {{ scoregegner.final | float2_0 }}
        </v-col>
        <template v-if="final && scoregegner.final">
          <v-col v-if="final > scoregegner.final" cols="3">
            {{ calcscore(final, scoregegner.final) }} Scorepunkte für {{ value | person}}
          </v-col>
          <v-col v-else cols="3">
            {{ calcscore(scoregegner.final, final) }} Scorepunkte für {{ scoregegner | person}}
          </v-col>
        </template>
      </template>
      <v-col cols="12" style="text-align: right;">
        <span v-if="parts > 8" class="mr-8" style="color:red;font-weight:bold;">zu viele Teile eingegeben!</span>
        <v-btn @click="del" style="margin-right:16px;">Löschen</v-btn>
        <v-btn @click="save" style="margin-right:16px;" :disabled="parts > 8">Speichern und weiter</v-btn>
        <v-btn @click="close">Schließen</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'
import { useCalc } from '@/views/components/stb_m_2021/plugins/calc'
import { resultquery } from '@/views/components/stb_m_2021/content/input/query'
import gql from 'graphql-tag'
import Sprungtabelle from './sprungtabelle'

export default {
  name: 'ergebnis',

  setup (props, context) {
    return {
      ...useGraphQL(context),
      ...useCalc()
    }
  },

  components: {
    Punktwaehler: () => import('./punktewaehler'),
    Anzahlwaehler: () => import('./anzahlwaehler')
  },

  props: {
    id: {
      type: String,
      default: null
    },
    did: {
      type: String,
      default: null
    },
    df: {
      type: Array,
      required: true
    },
    e: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    value: {},
    Result: {},
    ResultFind: [],
    sprungfilter: {
      absprung: 0,
      f1: -1,
      ausfuehrung: -1,
      lad: -1,
      bad: -1
    }
  }),

  computed: {
    gegner () {
      return this.e.teams.find(t => t.team._id !== this.Result._team)?.team?._id || null
    },
    scoregegner () {
      return this.ResultFind.find(r => r.order === this.value.order)
    },
    istSprung () {
      return this.disciplinename === 'Sprung'
    },
    istScore () {
      return this.e.mode === 'wk_score4'
    },
    disciplinename () {
      return this.df?.find(d => d._id === this.did)?.name
    },
    inline () {
      return !!['xl'].find(b => b === this.$vuetify.breakpoint.name)
    },
    kari_e1 () {
      return this.$options.filters.person(this.kari(this.e, this.value?._discipline, 'e1')) || null
    },
    kari_e2 () {
      return this.$options.filters.person(this.kari(this.e, this.value?._discipline, 'e2')) || null
    },
    kari_e3 () {
      return this.$options.filters.person(this.kari(this.e, this.value?._discipline, 'e3')) || null
    },
    kari_e4 () {
      return this.$options.filters.person(this.kari(this.e, this.value?._discipline, 'e4')) || null
    },
    sprungjahr () {
      const jahr = parseInt(this.e?.parent?.parent?.startDate?.split('-')?.[0])
      const ids = Object.keys(Sprungtabelle?.[0]?.ids).map(j => parseInt(j)).sort()
      return ids.findLast(i => i <= jahr)
    },
    spruenge () {
      return Sprungtabelle.map(s => ({ ...s, nr: s.ids[this.sprungjahr] })).filter(s => s.nr !== -1).sort((a, b) => a.nr < b.nr ? -1 : 1)
    },
    favspruenge () {
      return Sprungtabelle.filter(s => s.fav)
    },
    sf_f1 () {
      return Sprungtabelle
        .filter(s => s.absprung === this.sprungfilter.absprung)
        .reduce((acc, curr) => {
          if (acc.find(a => a === curr.f1) === undefined) acc.push(curr.f1)
          return acc
        }, []).sort()
    },
    sf_f2_lad () {
      return Sprungtabelle
        .filter(s => s.absprung === this.sprungfilter.absprung && (this.sprungfilter.f1 === -1 || s.f1 === this.sprungfilter.f1))
        .reduce((acc, curr) => {
          if (acc.find(a => a === curr.f2) === undefined) acc.push(curr.f2)
          return acc
        }, []).sort()
    },
    sf_f2_bad () {
      return Sprungtabelle
        .filter(s => s.absprung === this.sprungfilter.absprung && (this.sprungfilter.f1 === -1 || s.f1 === this.sprungfilter.f1) && (this.sprungfilter.lad === -1 || s.f2 === this.sprungfilter.lad))
        .reduce((acc, curr) => {
          if (acc.find(a => a === curr.bad) === undefined) acc.push(curr.bad)
          return acc
        }, []).sort()
    },
    sf_ausfuehrung () {
      return Sprungtabelle
        .filter(s => s.absprung === this.sprungfilter.absprung && (this.sprungfilter.f1 === -1 || s.f1 === this.sprungfilter.f1) && (this.sprungfilter.lad === -1 || s.f2 === this.sprungfilter.lad) && (this.sprungfilter.bad === -1 || s.bad === this.sprungfilter.bad))
        .reduce((acc, curr) => {
          if (acc.find(a => a === curr.ausfuehrung) === undefined) acc.push(curr.ausfuehrung)
          return acc
        }, []).sort().map(s => {
          if (s === 0) return { id: 0, label: 'ohne' }
          if (s === 1) return { id: 1, label: 'geh.' }
          if (s === 2) return { id: 2, label: 'geb.' }
          if (s === 3) return { id: 3, label: 'gest.' }
        })
    },
    sf () {
      const tmp = this.spruenge
        .filter(s =>
          s.absprung === this.sprungfilter.absprung &&
          (this.sprungfilter.f1 === -1 || s.f1 === this.sprungfilter.f1) &&
          (this.sprungfilter.lad === -1 || s.f2 === this.sprungfilter.lad) &&
          (this.sprungfilter.bad === -1 || s.bad === this.sprungfilter.bad) &&
          (this.sprungfilter.ausfuehrung === -1 || s.ausfuehrung === this.sprungfilter.ausfuehrung)
        )
      if (tmp.length === 1 && this.value._sprung !== tmp[0]._id) this.setSprung(tmp[0]._id)
      return tmp
    },
    deduction () {
      if (this.istSprung && this.dscore === 0) return 0

      const data = this.value

      const d = []

      if (this.kari_e1) d.push(data.e1)
      if (this.kari_e2) d.push(data.e2)
      if (this.kari_e3) d.push(data.e3)
      if (this.kari_e4) d.push(data.e4)

      d.sort((a, b) => a < b ? -1 : 1)

      if (d.length === 0) return 0
      if (d.length === 1) return d[0]
      if (d.length === 2) return Math.round((d[0] + d[1]) * 50) / 100
      if (d.length === 3) {
        const diff01 = d[1] - d[0]
        const diff12 = d[2] - d[1]
        if (Math.abs(diff12 - diff01) < 0.01) return Math.round(d[1] * 100) / 100
        if (diff01 < diff12) return Math.round((d[0] + d[1]) * 50) / 100
        return Math.round((d[1] + d[2]) * 50) / 100
      }
      if (d.length === 4) return Math.round((d[1] + d[2]) * 50) / 100

      return 0
    },
    parts () {
      return (this.value.ne || 0) +
        (this.value.a || 0) +
        (this.value.b || 0) +
        (this.value.c || 0) +
        (this.value.d || 0) +
        (this.value.e || 0) +
        (this.value.f || 0) +
        (this.value.g || 0) +
        (this.value.h || 0) +
        (this.value.i || 0)
    },
    dscore () {
      if (this.istSprung) {
        const sprung = this.spruenge.find(s => s._id === this.value._sprung)
        if (!sprung) return 0

        return this.value.bonus ? sprung.punkte + sprung.bonus : sprung.punkte
      }
      return Math.round((
        (this.value.eg || 0) +
        (this.value.ne || 0) * 0 +
        (this.value.a || 0) * 0.2 +
        (this.value.b || 0) * 0.4 +
        (this.value.c || 0) * 0.6 +
        ((this.value.d || 0) +
          (this.value.e || 0) +
          (this.value.f || 0) +
          (this.value.g || 0) +
          (this.value.h || 0) +
          (this.value.i || 0)) * 0.8) * 10) / 10
    },
    escore () {
      if (this.istSprung && !this.dscore) return 0
      return Math.round((10 - this.deduction) * 100) / 100
    },
    penalty () {
      if (this.istSprung && this.sprung === 0) return 0

      return Math.round(((this.istSprung ? 0 : 8 - Math.min(8, this.parts)) + this.value.ok) * 10) / 10
    },
    final () {
      if (this.istSprung && this.dscore === 0) return 0
      return Math.max(0, Math.round((this.dscore + this.escore - this.penalty) * 100) / 100)
    }
  },

  methods: {
    setSprung (id) {
      const sp = Sprungtabelle.find(s => s._id === id)
      if (!sp) {
        this.value._sprung = null
        this.sprungfilter.absprung = 0
        this.sprungfilter.f1 = -1
        this.sprungfilter.lad = -1
        this.sprungfilter.bad = -1
        this.sprungfilter.ausfuehrung = -1
      } else {
        this.value._sprung = id
        this.sprungfilter.absprung = sp.absprung
        this.sprungfilter.f1 = sp.f1
        this.sprungfilter.lad = sp.f2
        this.sprungfilter.bad = sp.bad
        this.sprungfilter.ausfuehrung = sp.ausfuehrung
      }
    },
    sprungname (sprung) {
      if (sprung.name) return `${sprung.name} (${sprung.beschreibung})`
      return sprung.beschreibung
    },
    del () {
      if (this.istScore && this.scoregegner?.final != null) {
        this.$emit('del', this.scoregegner._id)
      } else {
        this.$emit('del')
      }
    },
    save () {
      this.$emit('save', {
        ...this.value,
        deduction: this.deduction,
        parts: this.parts,
        dscore: this.dscore,
        escore: this.escore,
        penalty: this.penalty,
        final: this.final,
        score: this.istScore && this.scoregegner?.final != null ? this.calcscore(this.final, this.scoregegner.final) : null
      })
      if (this.istScore && this.scoregegner?.final != null) {
        this.$emit('save', {
          _id: this.scoregegner._id,
          score: this.calcscore(this.scoregegner.final, this.final)
        })
      }
    },
    close () {
      this.$emit('close')
    }
  },

  apollo: {
    Result: {
      query: gql`query($id: UUID!) {
        Result(id: $id) { ${resultquery} }
      }`,
      variables () {
        return {
          id: this.id
        }
      },
      skip () {
        return !this.id
      },
      result ({ data }) {
        if (!data) return
        this.value = JSON.parse(JSON.stringify(data.Result))
        console.log(this.value)
        if (this.istSprung && this.value.final === null) {
          this.value.bonus = true
          this.value._sprung = null
          this.setSprung(null)
        }
        return null
      }
    },
    ResultFind: {
      query: gql`query($event: UUID, $team: UUID, $discipline: UUID) {
        ResultFind(event: $event, team: $team, discipline: $discipline) { ${resultquery} }
      }`,
      variables () {
        return {
          event: this.e._id,
          team: this.gegner,
          discipline: this.did
        }
      },
      skip () {
        return !this.gegner
      }
    },
    $subscribe: {
      ResultUpdate: {
        query: gql`subscription($id: UUID!) {
          ResultUpdate(id: $id) { ${resultquery} }
        }`,
        variables () {
          return {
            id: this.gegner
          }
        }
      }
    }
  }
}
</script>

<style scoped>
</style>
